$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cursor standard
    // *
    // *
    $('.js-cursor-wrapper').on('mousemove', function(e) {
        var cursorHeight = $(this).find('.js-cursor').outerHeight()/2;
        var cursorWidth = $(this).find('.js-cursor').outerWidth()/2;
        $(this).find('.js-cursor').offset({left: e.pageX - cursorWidth, top: e.pageY - cursorHeight});
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cursor box teaser
    // *
    // *
    $('.js-cursor-wrapper-offset').on('mousemove', function(e) {
        var cursorHeight = $(this).find('.js-cursor').outerHeight()/2;
        var cursorWidth = $(this).find('.js-cursor').outerWidth();
        $(this).find('.js-cursor').offset({left: e.pageX - cursorWidth, top: e.pageY - cursorHeight});
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        var $button = $(this);

        // remove active class of open buttons
        $button.parents(".js-accordion-item").siblings(".open").find(".js-accordion-button").removeClass("active");

        // close open items
        $button.parents(".js-accordion-item").siblings(".open").toggleClass("open").find(".js-accordion-toggle").toggle();

        if($button.hasClass("active")) {
            // close current
            $button.removeClass("active").parents(".js-accordion-item").removeClass("open").find(".js-accordion-toggle").slideToggle();
        }else {
            // open current
            $("html").animate({scrollTop: $button.offset().top - 150},400);
            $button.addClass("active").parents(".js-accordion-item").addClass("open").find(".js-accordion-toggle").slideToggle();
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * keep accordion open between pages
    // *
    // *
    $(".js-accordion-hash").click(function(){
        window.location.hash = $(this).parents(".js-accordion-item").attr("data-hash");
    });

    if($(".js-accordion-hash").length) {
        var hash = window.location.hash.split('#')[1],
        $modal = $('[data-hash="' + hash + '"]');

        if ($modal.length) {
            $modal.find(".js-accordion-button").trigger("click");
        }
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky header
    // *
    // *
    $(window).scroll(function() {
        if($(window).scrollTop() >= 400) {
            $(".js-header-landing").removeClass("big");
        }else {
            $(".js-header-landing").addClass("big");
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggle nav
    // *
    // *
    $(".js-nav-button").click(function(event){
        $(".js-header").toggleClass("nav-open");
        $(".js-nav").toggleClass("active");
        // $("body").toggleClass("freeze");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav accordion
    // *
    // *
    $(".js-nav-accordion-button").click(function(event){
        event.preventDefault();
        $(this).toggleClass("active").siblings(".js-nav-accordion-content").slideDown().parents(".js-nav-accordion-item").siblings().find(".js-nav-accordion-content").slideUp().siblings(".js-nav-accordion-button").removeClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * imageSlider
	// *
	// *
    var $imageSlider = $(".js-image-slider");

	if ($imageSlider.length){
		$imageSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: false,
				arrows: true,
                autoplay: false,
                adaptiveHeight: true,
				speed: 1000,
                prevArrow: $(this).parents(".js-image-slider-wrapper").find(".js-image-slider-nav-prev"),
				nextArrow: $(this).parents(".js-image-slider-wrapper").find(".js-image-slider-nav-next"),
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggle filter button
    // *
    // *
    $(".js-filter-button").click(function(event){
        $(this).toggleClass("active").parents(".js-filter-wrapper").find(".js-filter-content").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter elements
    // *
    // *
    if ($(".js-filter-elements").length) {
        var mixer = mixitup('.js-filter-elements', {
            selectors: {
                target: '.js-filter-element'
            },
            load: {
                filter: 'all'
            }
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * card teaser shield
    // *
    // *
    $(".js-card-teaser-shield").click(function(){
        $(this).hide().parents(".js-filter-element").addClass("active");
        $(this).parents(".js-filter-element").siblings().removeClass("active").find(".js-card-teaser-shield").show();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * swiper
	// *
	// *
    const swiper = new Swiper('.swiper-container', {
        loop: true,
        freeMode: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
            nextEl: '.js-swiper-button-next',
            prevEl: '.js-swiper-button-prev',
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: true
        },
        speed: 1000,
        breakpoints: {
            1000: {
                autolay: false,
                freeModeSticky: true
            }
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * info box
    // *
    // *
    $(".js-info-box-button").click(function(){

        // mobile
        if($(".js-info-box-button.js-open").length > 0){
            $("html").animate({scrollTop: $(this).offset().top - 1000},400);
        }else {
            $("html").animate({scrollTop: $(this).offset().top + 1000},400);
        }

        $(this).toggleClass("js-open");
        $(this).parents(".js-info-box").toggleClass("active");

    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggle
    // *
    // *
    $(".js-toggle-button").click(function(event){
        event.preventDefault();
        $(this).parents(".js-toggle-wrapper").find(".js-toggle-button").toggle();
        $(this).parents(".js-toggle-wrapper").find(".js-toggle-content").slideToggle();
    });



});
